//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scrollTop from '@/lib/common-service/scrollTop'
import list from '@/lib/data-service/flight/fligthInternationalAdmin/flight_inter_buyer_manage_order_findRefundOrderList.js'
import {internationalAirLines} from '@/lib/data-service/flight/fligthInternational'
export default {
  data() {
    return {
      searchForm: {
        currentPage:1,
        pageSize:10
      },
      totalCount:0,
      flightAirlines: [],
      changeRecordList:[
        {text:'全部',value:''},
        {text:'退票处理中',value:41},
        {text:'退票完成',value:42},
        {text:'退票失败',value:43},
        {text:'退款中',value:44},
        {text:'已退款',value:45}
      ],
      NavItemActive:'',
      pageData:[],
      loading:false,
      airlinesList:[{
          airlineCode: '',
          airCompanyName: '不限',
          airCompanyEnglistName:'',
          airCompanyShortName:'',
          airlineCode:'',
          countryState:''
        }]
    };
  },
  created(){
    this.search()
    this.getAirlines()
  },
  methods: {
    statuSearch(item,index){
       this.NavItemActive = index
       this.searchForm.refStatus = item.value
       this.search()
    },
    searchClear() {

    },
    // 日期选择
    selectPickerOptions() {
      return {
        disabledDate: time => {
            return time.getTime() > Date.now()
        }
      }
    },
     // 获取航司列表
    getAirlines() {
      internationalAirLines().then(data => {
        // console.log(data)
        let airlinesList = [...this.airlinesList,...data.pageResult.pageData]
        this.airlinesList = airlinesList
      })
    },
    reset() {
      this.searchForm = {currentPage:1,pageSize:10}
    },
    search(pageNum) {
      let data = this.searchForm
      if(typeof pageNum == 'number'){
          data.currentPage = pageNum||1
      }else{
        data.currentPage = 1
      }
      data.startRefDate?data.endRefDate = this.$moment().format('YYYY-MM-DD'):data.endRefDate = ''
      list(data).then(res=>{
        this.pageData = res.pageResult.pageData
        this.totalCount = res.pageResult.totalCount
        scrollTop(500)
      })
    },
    setOrdersNo(item){
        this.$router.push({name:'air-ticket-admin-international-list-order-detail',query:{ordersNo:item,type:'cancel'},params:{refresh:1}})
    },

    handlerSize(val){
      this.searchForm.pageSize = val
      this.search()
    },
    handlerPage(val){
      // this.searchForm.currentPage = val
      this.search(val)
    }
  }
};
